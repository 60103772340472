<template>
  <validation-observer slim v-slot="{ handleSubmit }">
    <app-page-layout :title="$t('hr.training.request_training')" icon="tw-expense" @close="leavePage">
      <template #content>
        <v-row>
          <v-col cols="12">
            <validation-provider :name="$t('hr.training.training')" slim rules="required" v-slot="{ errors }">
              <training-picker v-model="trainingRequest.training" :disabled="loading" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('hr.expense.date')" slim rules="required" v-slot="{ errors }">
              <date-time-picker
                v-model="trainingRequest.requestDateTime"
                format="YYYY-MM-DDTHH:mm:ss"
                :max="$moment().format('YYYY-MM-DDTHH:mm:ss')"
                :disabled="loading"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('global.detail')" slim rules="max: 2048" v-slot="{ errors }">
              <v-textarea
                v-model="trainingRequest.detail"
                counter="2048"
                color="tertiary"
                outlined
				rows="4"
                :label="$t('global.detail')"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </template>
      <template #footer>
        <app-page-footer create>
          <template #right>
            <v-btn text @click="handleSubmit(validateAndSave)" :loading="loading">{{ $t("buttons.save") }}</v-btn>
          </template>
        </app-page-footer>
      </template>
    </app-page-layout>
  </validation-observer>
</template>

<script>
  import TrainingRequest from "../model/TrainingRequest";

  export default {
    name: "TrainingRequestCreate",
    components: {
      TrainingPicker: () => import("@/components/selectpickers/TrainingPicker"),
      AppPageFooter: () => import("@/layouts/app-page-footer")
    },
    data: () => ({
      loading: false,
      trainingRequest: new TrainingRequest()
    }),
    methods: {
      leavePage() {
        this.$router.replace({ name: "training_request" });
      },
      validateAndSave() {
        this.loading = true;
        this.$api.trainingRequestService
          .save(this.trainingRequest)
          .then(response => {
            if (response.status === 201) {
              this.$emit("success:save");
              this.leavePage();
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>
