var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('app-page-layout',{attrs:{"title":_vm.$t('hr.training.request_training'),"icon":"tw-expense"},on:{"close":_vm.leavePage},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.training.training'),"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('training-picker',{attrs:{"disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.trainingRequest.training),callback:function ($$v) {_vm.$set(_vm.trainingRequest, "training", $$v)},expression:"trainingRequest.training"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.expense.date'),"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"format":"YYYY-MM-DDTHH:mm:ss","max":_vm.$moment().format('YYYY-MM-DDTHH:mm:ss'),"disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.trainingRequest.requestDateTime),callback:function ($$v) {_vm.$set(_vm.trainingRequest, "requestDateTime", $$v)},expression:"trainingRequest.requestDateTime"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.detail'),"slim":"","rules":"max: 2048"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":"2048","color":"tertiary","outlined":"","rows":"4","label":_vm.$t('global.detail'),"error-messages":errors},model:{value:(_vm.trainingRequest.detail),callback:function ($$v) {_vm.$set(_vm.trainingRequest, "detail", $$v)},expression:"trainingRequest.detail"}})]}}],null,true)})],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('app-page-footer',{attrs:{"create":""},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.validateAndSave)}}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])]},proxy:true}],null,true)})]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }